import { defineComponent } from 'vue';
import moment from "moment";
import QrScanner from 'qr-scanner';
import axios from "axios";
QrScanner.WORKER_PATH = '/qr-scanner-worker.min.js';
export default defineComponent({
    name: "QuestionsPage",
    props: ["eventId", "keycloak"],
    components: {},
    data() {
        return {
            windowInnerHeight: 0,
            qrScanner: undefined,
            audio: new Audio('/beep.mp3'),
            ticketValidated: false,
            validationStatusCode: 0,
            totalTickets: 0,
            validatedTickets: 0,
            initialValidatedTickets: 0,
            qrCode: undefined,
            firstName: "",
            lastName: ""
        };
    },
    computed: {},
    watch: {},
    methods: {
        formatTime(time) {
            return moment(time).calendar();
        },
        processQrCode(qrcode) {
            console.log('decoded qr code:', qrcode);
            if (qrcode === "")
                return;
            this.qrScanner?.stop();
            let me = this;
            me.qrCode = qrcode;
            axios.post('/tickets/' + qrcode + '/scan', {
                "eventId": this.eventId,
                "operation": "SCAN"
            }, {
                "validateStatus": function () { return true; }
            }).then(function (response) {
                console.log(response);
                me.validationStatusCode = response.status;
                if (response.status == 200) {
                    me.validatedTickets = response.data.validatedTicketCount;
                    me.initialValidatedTickets = me.validatedTickets;
                    me.totalTickets = response.data.totalTicketCount;
                    me.firstName = response.data.user.firstName;
                    me.lastName = response.data.user.lastName;
                }
                else {
                    me.initialValidatedTickets = 0;
                    me.validatedTickets = 0;
                    me.totalTickets = 0;
                    me.firstName = "";
                    me.lastName = "";
                }
                me.ticketValidated = true;
            });
            window.navigator.vibrate(200);
            this.audio.play();
        },
        updatePeopleCount(n) {
            let me = this;
            axios.post('/tickets/' + this.qrCode + '/scan', {
                "eventId": this.eventId,
                "operation": "UPDATE_PEOPLE_COUNT",
                "peopleCount": n
            }, {
                "validateStatus": function () { return true; }
            }).then(function (response) {
                console.log(response);
                me.validationStatusCode = response.status;
                if (response.status == 200) {
                    me.validatedTickets = response.data.validatedTicketCount;
                    me.totalTickets = response.data.totalTicketCount;
                }
                else {
                    me.validatedTickets = 0;
                    me.totalTickets = 0;
                }
                me.ticketValidated = true;
            });
        },
        startCamera() {
            this.qrScanner?.start();
        },
        stopCamera() {
            this.qrScanner?.stop();
        },
        toggleFlash() {
            this.qrScanner?.toggleFlash();
        }
    },
    mounted() {
        const vm = this;
        window.addEventListener('resize', () => {
            vm.windowInnerHeight = window.innerHeight;
        });
        //@ts-ignore
        this.$refs.webcam.addEventListener('loadedmetadata', function () {
            //@ts-ignore
            vm.$refs.overlay.style.width = `${vm.$refs.webcam.offsetWidth}px`;
            //@ts-ignore
            vm.$refs.overlay.style.height = `${vm.$refs.webcam.offsetHeight}px`;
            //@ts-ignore
            let borderSpaceLeftRight = vm.$refs.webcam.offsetWidth / 4;
            //@ts-ignore
            let borderSpaceTopBottom = (vm.$refs.webcam.offsetHeight - vm.$refs.webcam.offsetWidth + (2 * borderSpaceLeftRight)) / 2;
            //@ts-ignore
            vm.$refs.overlay.style.setProperty('--border-space-topbottom', `${borderSpaceTopBottom}px`);
            //@ts-ignore
            vm.$refs.overlay.style.setProperty('--border-space-leftright', `${borderSpaceLeftRight}px`);
        });
        moment.locale("de");
        //@ts-ignore
        this.qrScanner = new QrScanner(this.$refs.webcam, result => this.processQrCode(result));
        this.qrScanner.start();
    }
});
