import { defineComponent } from 'vue';
import axios from "axios";
import moment from "moment";
export default defineComponent({
    name: "EventSelector",
    data() {
        return {
            events: Array(Event)
        };
    },
    methods: {
        formatTime(time) {
            return moment(time).calendar();
        }
    },
    mounted() {
        console.log("Loading events");
        axios
            .get("https://api.groundlift.live/api/v1/sites/groundliftlive/events")
            .then(value => (this.$data.events = value.data));
        moment.locale("de");
    }
});
